import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-user-list-settings',
  templateUrl: './user-list-settings.component.html',
  styleUrls: ['./user-list-settings.component.scss'],
})
export class UserListSettingsComponent implements OnInit {

  qrEnabled = false;
  changeDealerEnabled = false;
  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  generateQr() {
    this.dismissPopover({event: 'GENERATE_QR'});
  }

  changeDealer() {
    this.dismissPopover({event: 'CHANGE_DEALER'});
  }

  dismissPopover(data?: any) {
    this.popoverController.dismiss(data);
  }

}
