import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Dealer } from 'src/app/models';

@Component({
    selector: 'app-map-alert',
    templateUrl: './map-alert.component.html',
    styleUrls: ['./map-alert.component.scss'],
})
export class MapAlertComponent {
    path: string;
    dealer: Dealer;
    coords: { lat: number; lng: number };
    options: google.maps.MapOptions = {
        disableDefaultUI: true,
        styles: [
            {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ],
    };
    constructor(private popoverController: PopoverController) {}

    cancel() {
        this.dismissPopover({ event: 'CANCEL' });
    }

    retry() {
        this.dismissPopover({ event: 'RETRY' });
    }

    dismissPopover(data?: any) {
        this.popoverController.dismiss(data);
    }
}
