import { Inject, Injectable } from '@angular/core';
import { BarcodeScanner, BarcodeScanResult } from '@ionic-native/barcode-scanner/ngx';
import { APP_CONFIG, IAppConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class BarcodeService {
    constructor(@Inject(APP_CONFIG) private config: IAppConfig, private scanner: BarcodeScanner) {}

    async scan(): Promise<string> {
        if (!this.config.isProd) {
            return prompt();
        }
        const result: BarcodeScanResult = await this.scanner.scan({ orientation: 'portrait' }).catch((err) => {
            console.error(err);
            return null;
        });
        return result ? result.text : null;
    }
}
