import { Component, Inject, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, NavController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { BarcodeService } from 'src/app/services/barcode.service';
import { User } from '../../models';
import { AuthService } from '../../providers/auth-service/auth.service';
import { InformationService } from '../../providers/information-service/information.service';
import { GlobalEvents } from '../../services/events.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    user: User;
    hasUserListPermission: boolean;
    appVersion: string;
    changeDealerEnabled: boolean;
    hasEnrollFace: boolean;
    constructor(
        @Inject(APP_CONFIG) config: IAppConfig,
        private translate: TranslateService,
        public actionSheetCtrl: ActionSheetController,
        private popoverController: PopoverController,
        private authService: AuthService,
        private navCtrl: NavController,
        public alertController: AlertController,
        private informationService: InformationService,
        private events: GlobalEvents,
        private barcode: BarcodeService
    ) {
        this.appVersion = config.appVersion;
    }

    async ngOnInit() {
        this.user = await this.authService.getUser();
    }

    dismissPopover() {
        this.popoverController.dismiss();
    }

    presentActionSheet() {
        this.dismissPopover();
        this.translate.get(['LANGUAGE', 'ENGLISH', 'SPANISH', 'CANCEL']).subscribe(async (res) => {
            const actionSheet = await this.actionSheetCtrl.create({
                header: res.LANGUAGE,
                buttons: [
                    {
                        text: res.ENGLISH,
                        handler: () => {
                            this.changeLang('en');
                        },
                    },
                    {
                        text: res.SPANISH,
                        handler: () => {
                            this.changeLang('es');
                        },
                    },
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                ],
            });
            actionSheet.present();
        });
    }

    async logout() {
        const t = await this.translate
            .get(['LOGOUT_ERROR', 'LOGOUT', 'CANCEL', 'ACCEPT', 'LOGOUT_WARNING'])
            .toPromise();
        const codigoInterno = await this.barcode.scan();
        const loggedUser = await this.authService.getUser();
        const verifiedUser = await this.authService.getUserFromInternalCode(codigoInterno);
        if (+loggedUser.id !== +verifiedUser.id) {
            const alert = await this.alertController.create({
                header: t.LOGOUT_ERROR,
                buttons: [
                    {
                        text: t.ACCEPT,
                    },
                ],
            });
            this.dismissPopover();
            await alert.present();
            return;
        }
        const alert = await this.alertController.create({
            header: t.LOGOUT,
            message: t.LOGOUT_WARNING,
            buttons: [
                {
                    text: t.CANCEL,
                    role: 'cancel',
                },
                {
                    text: t.ACCEPT,
                    handler: async () => {
                        this.events.publish({ event: 'LOGOUT', data: null });
                    },
                },
            ],
        });
        this.dismissPopover();
        await alert.present();
    }

    async changeDealer() {
        this.events.publish({ event: 'change_dealer_modal', data: null });
        this.dismissPopover();
    }

    changeLang(lang) {
        this.informationService.changeLang(lang);
    }

    gotToShiftHistory() {
        this.dismissPopover();
        this.navCtrl.navigateForward('/shift-history');
    }

    gotToUserList() {
        this.dismissPopover();
        this.navCtrl.navigateForward('/user-list');
    }

    changeCompany() {
        this.events.publish({ event: 'change_company_modal', data: null });
        this.dismissPopover();
    }

    async enrollFace() {
        // const codigoInterno = await this.barcode.scan();
        // const verifiedUser = await this.authService.getUserFromInternalCode(codigoInterno);
        // const provider = await this.authService.getActiveProvider();
        // if (verifiedUser.provider.find((p) => p.id === provider.id)?.permissions.includes(115)) {
        this.events.publish({ event: 'enroll_face', data: null });
        this.dismissPopover();
        return;
        // } else {
        //     console.log('no permission');
        // }
    }
}
