import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Platform } from '@ionic/angular';
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class PhotoService {
    private isDom: boolean;
    constructor(private screenOrientation: ScreenOrientation, private platform: Platform) {
        platform.ready().then((value) => {
            this.isDom = value == 'dom';
        });
    }

    async takePicture(quality: number) {
        try {
            this.screenOrientation.unlock();
            const photo = await Camera.getPhoto({
                quality,
                allowEditing: false,
                source: CameraSource.Camera,
                resultType: CameraResultType.DataUrl,
                direction: CameraDirection.Front,
            });
            if (!this.isDom) {
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
                this.clearPicturesFolder();
            }
            return photo.dataUrl;
        } catch (error) {
            return null;
        }
    }

    async clearPicturesFolder() {
        if (this.platform.is('android')) {
            const picturesFolder = await Filesystem.getUri({ directory: Directory.External, path: 'Pictures' });
            Filesystem.readdir({ path: picturesFolder.uri }).then((dir) => {
                dir.files.forEach((file) => {
                    Filesystem.deleteFile({ path: picturesFolder.uri + '/' + file });
                });
            });
        }
    }

    handleFileSelect(evt) {
        return new Promise<string>((resolve, reject) => {
            const files = evt.target.files;
            const file = files[0];
            if (files && file) {
                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = this._handleReaderLoaded.bind(this, resolve);
            }
        });
    }

    _handleReaderLoaded(resolve, readerEvt) {
        const binaryString = readerEvt.target.result;
        resolve('data:image/jpeg;base64,' + btoa(binaryString));
    }
}
