import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { User } from 'src/app/models';

@Component({
    selector: 'app-qr-popover',
    templateUrl: './qr-popover.component.html',
    styleUrls: ['./qr-popover.component.scss'],
})
export class QrPopoverComponent implements OnInit {
    user: User;
    qrcodeValue: string;
    errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.MEDIUM;
    type = NgxQrcodeElementTypes.IMG;
    constructor(private popoverController: PopoverController) {}

    ngOnInit() {}

    dismissPopover() {
        this.popoverController.dismiss();
    }
}
