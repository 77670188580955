import { Component, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CodeInputComponent } from 'angular-code-input';
@Component({
    selector: 'app-code-validator',
    templateUrl: './code-validator.component.html',
    styleUrls: ['./code-validator.component.scss'],
})
export class CodeValidatorComponent {
    @ViewChild('codeInput') codeInput!: CodeInputComponent;
    validateCode: (userId: number, dealerId: number, code: string) => Promise<boolean>;
    userId: number;
    dealerId: number;
    verificationMessage: string;
    constructor(
        private translate: TranslateService,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController
    ) {}

    onCodeChanged(event: any) {}
    async onCodeCompleted(code: string) {
        const result = await this.validateCode(this.userId, this.dealerId, code).catch((e) => {
            console.log(e);
            return false;
        });
        this.codeInput.reset();
        if (result) {
            this.dismiss(result);
        } else {
            const msg = await this.translate.get('INVALID_CODE').toPromise();
            const alert = await this.alertCtrl.create({
                message: msg,
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                        handler: async () => {
                            this.dismiss();
                        },
                    },
                ],
            });
            await alert.present();
        }
    }
    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
